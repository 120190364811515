<template>
  <div class="detail">
    <div class="img">
      <img :src="detail.coverImage" alt="" />
    </div>
    <div class="content">
      <div class="name">
        <p>{{ detail.title }}</p>
      </div>
      <div class="price">
        <p class="left">{{ detail.subTitle }}</p>
      </div>
      <p class="merchantName">
        <span>{{ detail.updateTime }}</span>
        <i v-if="detail.merchantName"></i>
        <span v-if="detail.merchantName">{{ detail.merchantName }}</span>
      </p>
      <div class="msg">
        <div v-for="(item,index) in detail.paragraphVOList" :key="index">
          <p v-if="item.type == 1">{{item.content}}</p>
          <img style="width:100%;" v-else :src="item.content" alt="">
        </div>
      </div>
      <!-- <div class="msg" @click="addComment($event)" v-html="detail.detail"></div> -->
      <!-- <div class="like">
        <h3>猜你喜欢</h3>
        <div class="list">
          <div class="item">
            <div class="left">
              <img src="" alt="" />
            </div>
            <div class="right">
              <div class="name">
                <p>阿迪达斯</p>
              </div>
              <div class="price">
                <p>122</p>
              </div>
              <div class="date">
                <div class="date-left">
                  <p>2020-10-10 12:00</p>
                  <i></i>
                  <p>考拉海购</p>
                </div>
                <div class="num">
                  <van-icon size="20px" name="eye-o" /><span>11</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <van-goods-action>
      <van-goods-action-icon
        icon="wap-home"
        color="#FF804D"
        text="首页"
        @click="index"
      />
      <van-goods-action-icon
        icon="service"
        text="客服"
        color="#FF804D"
        @click="call"
      />
      <!-- <van-goods-action-icon icon="star" text="收藏夹" color="#ff5000" /> -->
      <van-goods-action-icon
        icon="star"
        text="收藏夹"
        color="#FF804D"
        @click="bookmark"
      />
      <van-goods-action-button
        type="warning"
        text="加入收藏夹"
        v-if="!detail.isFavorite"
        @click="addFavorite"
      />
      <van-goods-action-button
        type="warning"
        text="取消收藏"
        v-if="detail.isFavorite"
        @click="deleteFavorite"
      />
      <van-goods-action-button type="danger" text="立即购买" @click="goBuy" />
    </van-goods-action>
    <van-popup v-model="show">
        <div class="callMsg">
          <img :src="shopMsg.shopkeeperContact" style="maxWidth:200px" alt="">
        </div>
      </van-popup>
  </div>
</template>

<script>
import {
  contentDetailSelf,
  getShopName,
  addFavorite,
  deleteFavorite,
} from "../../utils/api";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      detail: {},
      shopId: "",
      shopMsg: {},
      show:false
    };
  },
  methods: {
    //我的客服
    call(){
      this.show = true;
    },
    //获取店铺信息
    async getshopName() {
      await getShopName().then((res) => {
        // console.log(res)
        this.shopId = res.id;
        this.shopMsg = res;
      });
    },
    //立即购买
    goBuy() {
      window.location.href = this.detail.goBuyUrl;
    },
    //跳转收藏夹
    bookmark() {
      this.$router.push({
        path: `/${this.shopMsg.shopAlias}/myCollection`,
      });
    },
    //取消收藏
    deleteFavorite() {
      let params = {
        shopId: this.shopId,
        favoriteId: this.detail.favoriteId,
        contentId: this.detail.contentId,
        contentType: this.detail.contentType,
      };
      deleteFavorite(params).then((res) => {
        console.log(res);
        this.$toast.success("已取消收藏");
        this.contentDetailSelf();
      });
    },
    //加入收藏夹按钮
    addFavorite() {
      let params = {
        channelCode: this.detail.channelCode,
        contentId: this.detail.contentId,
        contentType: this.detail.contentType,
        merchantId: this.detail.merchantId,
        shopId: this.shopId,
      };
      addFavorite(params).then((res) => {
        console.log(res);
        this.$toast.success("已收藏");
        this.contentDetailSelf();
      });
    },
    //首页
    index() {
      this.$router.push({
        path: `/${this.shopMsg.shopAlias}/home`,
      });
    },
    //获取html自定义属性
    addComment(event) {
      if (event.target.className === "kouling") {
        let msg = event.target.getAttribute("data-kouling");
        event.target.setAttribute("data-clipboard-text", msg);
        let clipboard = new Clipboard(".kouling");
        clipboard.on("success", () => {
          this.$toast.success("复制成功");
          // 释放内存
          clipboard.destroy();
        });
        clipboard.on("error", () => {
          this.$toast.fail("该浏览器不支持自动复制");
          // 释放内存
          clipboard.destroy();
        });
      }
    },
    //获取详情
    async contentDetailSelf() {
       let _this = this;
      let params = {
        shopId: _this.shopId,
      };
     
      await contentDetailSelf(_this.$route.query.contentId, params)
        .then((res) => {
          _this.detail = res.data;
          // document.title = _this.detail.title;
          setTimeout(function () {
            _this.$toast.clear();
          }, 150);
        })
        .catch(() => {});
    },
  },
  async mounted() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    await this.getshopName();
    await this.contentDetailSelf();
  },
};
</script>

<style lang="scss" scoped>
// @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
//   .van-goods-action {
//     height: 84px;
//     padding-bottom: 34upx;
//     align-items: flex-start;
//   }
//   .van-goods-action-icon {
//     justify-content: flex-start;
//     margin-top: 15px;
//   }
//   .van-goods-action-button{
//     margin-top: 10px;
//   }
// }
// @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
//   .van-goods-action {
//     height: 84px;
//     padding-bottom: 34upx;
//     align-items: flex-start;
//   }
//   .van-goods-action-icon {
//     justify-content: flex-start;
//     margin-top: 15px;
//   }
//   .van-goods-action-button{
//     margin-top: 10px;
//   }
// }

// @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
//   .van-goods-action {
//     height: 84px;
//     padding-bottom: 34upx;
//     align-items: flex-start;
//   }
//   .van-goods-action-icon {
//     justify-content: flex-start;
//     margin-top: 15px;
//   }
//   .van-goods-action-button{
//     margin-top: 10px;
//   }
// }
$pirmary-color: #ff804d;
/deep/ .kouling {
  color: $pirmary-color;
  font-size: 20px;
  padding: 10px 0;
  text-decoration: underline;
}
/deep/ .van-goods-action-icon {
  color: #666;
}
/deep/ .van-goods-action-button--warning {
  background: $pirmary-color;
}
/deep/ .van-goods-action-button--danger {
  background: #fe5100;
}
.content {
  padding: 0 10px 100px;
}
.img {
  width: 100%;
  min-height: 180px;
  img {
    width: 100%;
  }
}
.name {
  width: 100%;
  margin-top: 20px;
  p {
    font-size: 18px;
    line-height: 25px;
    color: #333;
    text-align: justify;
    font-weight: bold;
  }
}
.merchantName {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
  line-height: 17px;
  color: #999;
  display: flex;
  i {
    width: 1px;
    height: 14px;
    background: #999;
    display: block;
    margin: 0 5px;
  }
}
.price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  .left {
    font-size: 18px;
    line-height: 25px;
    color: #e62828;
  }
}
.msg {
  margin-top: 15px;
  p{
    font-size: 14px;
    line-height: 20px;
    color: #666;
    text-align: justify;
    margin-top: 7px;
  }
  img{
    width: 100%;
    margin-top: 10px;
  }
  /deep/ .detail-box {
    font-size: 12px;
    line-height: 22px;
    color: #999;
  }
}
.like {
  width: 100%;
  margin-top: 40px;
  h3 {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .list {
    width: 100%;
    .item:first-child {
      border-top: 1px solid #eaeaea;
    }
    .item {
      width: 100%;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      padding: 10px;
      .left {
        width: 100px;
        height: 100px;
        background: chocolate;
        img {
          width: 100%;
        }
      }
      .right {
        width: 100%;
        flex: 1;
        padding-left: 10px;
        .name {
          p {
            font-size: 16px;
            line-height: 24px;
            color: #333;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-align: justify;
          }
        }
        .price {
          p {
            font-size: 16px;
            line-height: 16px;
            color: rgb(196, 62, 62);
          }
          margin: 10px 0 20px;
        }
        .date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .date-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #666;
            line-height: 14px;
            i {
              width: 1px;
              height: 14px;
              background: #666;
              margin: 0 5px;
            }
          }
          .num {
            display: flex;
            align-items: center;
            span {
              padding-left: 3px;
            }
          }
        }
      }
    }
  }
}
</style>